
blockquote {
  font-size: 1.2em;
  line-height: 1.6 !important;
  border-left: 3px solid #eee;
  margin: 0 !important;
  padding-left: 15px;
}

.ql-size-small {
  font-size: 0.8em;
}

.ql-size-large {
  font-size: 2em;
}

.ql-size-huge {
  font-size: 3em;
}

.product-language-modal {
  .modal-dialog {
    max-width: 900px;
  }
}