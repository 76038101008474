.message-type-table {


  thead, tbody, input, select {
    font-size: 0.8em;
  }

  td {
    padding: 5px !important;
  }

  textarea, button {
    font-size: 0.9em;
  }

  .minWidth100 {
    min-width: 100px;
  }

  .minWidth150 {
    min-width: 150px;
  }

  .minWidth200 {
    min-width: 200px;
  }

  .minWidth250 {
    min-width: 250px;
  }
}