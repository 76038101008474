.up-down-arrow-icon {
  cursor: pointer;
  border-radius: 5px;
  line-height: 0;
  transition: transform 300ms ease;
  user-select: none;
  vertical-align: middle;
  margin: 5px;
  height: 22px;
  width: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #007bff;
  color: white;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.up-down-arrow-icon.active {
  transform: rotate(180deg);
  transition: transform 300ms ease;
}
