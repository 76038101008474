.copy-field-bot-forms-list {
  width: 200px;
}

.bot-field-lang {
  display: inline-block;

  * {
    font-size: 15px !important;
  }
}
