
.connection-item {
  border: 1px solid #f5f5f5;
  padding: 2px 4px;
  margin: 2px 3px 2px 0;

  .connection-item-name {
    display: inline-block;
    margin-right: 2px;
  }

  button {
    background-color: white;
    border: 1px solid #f5f5f5;
    border-radius: 2px;
    padding: 3px;
    margin: 1px;
    font-size: 8px;

    &:hover {
      border-color: #777;
    }
  }
}

.connection-item-add {
  background-color: white;
  border: 1px solid #f5f5f5;
  border-radius: 2px;
  padding: 5px;
  margin: 1px;
  font-size: 8px;

  &:hover {
    border-color: #777;
  }
}
