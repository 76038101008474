.quiz-modal-language-select {
  width: 150px;
}

.quiz-modal-response-dict {
  height: 300px;
  overflow: auto;
  border: 2px solid #f5f5f5;
  border-radius: 10px;
  margin: 20px 0;
}
