.product-item-connection-item {
  position: relative;
  border-radius: 5px;
  border: 1px solid #f0f0f0;
  margin: 5px 0;
  padding: 10px;

  p {
    margin-bottom: 5px;
  }
}
