.expression-builder-box {
  position: fixed;
  background-color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 25px 20px;
  top: 20px;
  bottom: 20px;
  z-index: 7;
  overflow-y: auto;

  @media only screen and (max-width: 1176px) {
    right: 20px;
    left: 20px;
  }
  @media only screen and (min-width: 1177px) {
    left: 50%;
    transform: translate(-50%, 0);
    width: 1136px;
  }
  @media only screen and (max-width: 576px) {
    padding: 25px 10px;
  }
}

.expression-builder-title {
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: darkblue;
  }
}

.tree-node {
  .tree-node-name {
    font-size: 18px;
    font-weight: 700;
    color: darkblue;

  }
  .tree-node-type {
    display: none;
  }
}

.expression-list-item {
  //border-left: 2px solid #979797;
  border: 0.5px solid #f5f5f5;
  border-left: 3px solid #007bff;
  border-radius: 5px;
  box-shadow: -2px 2px 16px rgba(0, 0, 0, 0.3);
  padding: 5px 3px 5px 10px;
  margin: 20px 0 20px 8px;
  position: relative;
  //background-color: rgba(228, 228, 228, 0.3);
}

.expression-list-item .expression-list-item::before {
  content: "";
  width: 24px;
  //background-color: #979797;
  border-left: 2px solid #007bff;
  border-bottom: 2px solid #007bff;
  display: inline-block;
  position: absolute;
  right: 100%;
  height: 50%;
}

.expression-list-item-element {
  background-color: white;
  display: inline-block;
  padding: 5px 15px;
  margin: 3px 0;
  border-radius: 2px;
}

.expression-list-item-group-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;

  @media only screen and (max-width: 576px) {
    justify-content: center;
    flex-wrap: wrap;
  }

  .group-header-left {
    text-align: right;
  }

  .group-header-right {
  }

  .expression-add-group {
    margin-left: 10px;
    margin-right: -1px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .expression-add-expression {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .expression-add-group, .expression-add-expression {
    border: 1px solid black;
    cursor: pointer;
    padding: 2px 4px;
    font-size: 12px;
    font-weight: 700;
    color: darkblue;
    box-shadow: none;
    outline: none;
    background-color: white;
    transition: color 300ms ease, background-color 300ms ease;

    &:hover {
      color: white;
      background-color: darkblue;
      transition: color 300ms ease, background-color 300ms ease;
    }
  }
}

.expression-children {
  @media only screen and (max-width: 576px) {
    text-align: center;
  }
}

.expression-select {
  width: 150px;
}
