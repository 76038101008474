

table {
  tbody {
    font-size: 14px;
    font-weight: 500;
  }

  td {
    padding: 10px !important;
  }

  .order-cid {
    font-weight: bold;
  }
  .order-products {
    ul {
      padding-left: 15px;
      margin: 0;
      li {
        font-weight: 500;
        font-size: 13px;
      }
    }
  }
  .order-date {
  }
  .order-status {
    text-transform: capitalize;
  }
  .order-address {
  }
}

.expression-table-expression {
  max-width: 300px;
  overflow-wrap: break-word;
}
