a.card-container {
  color: black;

  &:hover {
    text-decoration: unset;
    color: black;
  }
}

.card-container {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 20px;

}
