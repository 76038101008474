.bot-field-file-item {
  position: relative;
  display: inline-block;
  button {
    position: absolute;
    top: -10px;
    right: 0px;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    padding: 0;
    z-index: 2;
  }
  img {
    width: 150px;
    height: 150px;
    object-fit: contain;
    margin-left: 10px;
  }
}
