$light-blue: #DCEAFF;

.sidebar {
  background-color: black;
  position: fixed;
  top: 0;
  bottom: 0;
  left: -200px;
  width: 250px;
  z-index: 5;
  transition: left 300ms ease;
  //@media only screen and (max-width: 991px) {
  //  left: -200px;
  //}
}

.sidebar.active {
  left: 0;
  transition: left 300ms ease;

  .sidebar-item {
    flex-direction: row;
  }
}

.sidebar-toggle {
  float: right;
  margin-top: 5px;
  margin-right: 10px;
  padding: 5px;
  color: $light-blue;
  font-weight: 700;
  font-size: 20px;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transform: rotate(0deg);
  transition: box-shadow 300ms ease, transform 300ms ease;

  &:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.05);
    transition: box-shadow 300ms ease;
  }
}

.sidebar-toggle.active {
  transform: rotate(180deg);
  transition: transform 300ms ease;
}

.sidebar-items {
  padding: 0;
  margin: 0;
}

.sidebar-item-container {
  ul {
    padding: 0;
  }
  .sidebar-item {
    padding-left: 30px;
    background-color: rgba(0, 0, 0, 0.1);
    border-left: 4px solid transparent;
  }
  .sidebar-item:first-child {
    padding-left: 20px;
    background-color: rgba(0, 0, 0, 0);
    border-left: 4px solid transparent;
  }
  .sidebar-item.active {
    border-left: 4px solid $light-blue;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .sidebar-item:first-child.active {
    padding-left: 20px;
    background-color: rgba(0, 0, 0, 0.1);
    border-left: 4px solid transparent;
  }
}

.sidebar-item {
  color: $light-blue;
  padding: 10px 15px 10px 20px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  user-select: none;
  border-left: 4px solid transparent;

  .side-item-text {
    padding-left: 15px;
    display: flex;
    flex: 1;
  }

  .material-icons {
    font-size: 20px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.sidebar-item.active {
  background-color: rgba(0, 0, 0, 0.1);
  border-left: 4px solid $light-blue;
  font-weight: 700;
}

.sidebar-header {
  padding: 20px 20px 10px 20px;
  color: white;
  font-weight: 900;
  font-size: 24px;
}

.sidebar-container {
  //padding-left: 50px;
  padding-left: 0;
  transition: padding-left 300ms ease;
}

.sidebar-container.active {
  @media only screen and (min-width: 991px) {
    padding-left: 250px;
    transition: padding-left 300ms ease;
  }
}
