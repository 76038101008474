table {
  tbody {
    font-size: 14px;
    font-weight: 500;
  }

  td {
    padding: 10px !important;
  }

  .order-cid {
    font-weight: bold;
  }

  .order-products {
    ul {
      padding-left: 15px;
      margin: 0;

      li {
        font-weight: 500;
        font-size: 13px;
      }
    }
  }

  .order-date {
  }

  .order-status {
    text-transform: capitalize;
  }

  .order-address {
  }
}

.bot-edit-page {
  overflow-x: auto;
}


.expression-response-container {
  margin: 10px 0;
  padding: 40px 15px 15px 15px;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  background-color: white;

  max-height: 350px;
  overflow: auto;

  &.expand {
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    max-height: unset;
    padding-top: 80px;
  }

  .expression-response-ab-1 {
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 5;
  }

  .expression-response-ab-2 {
    position: absolute;
    top: 25px;
    right: 100px;
    z-index: 5;
  }
}
