.delete-icon {
  display: inline-flex;
  user-select: none;
  vertical-align: middle;
  background-color: #007bff;
  border-radius: 3px;
  height: 25px;
  min-width: 30px;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  padding: 0 5px;

  span {
    padding: 2px;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
    color: white;
    i {
      vertical-align: text-bottom;
      font-size: 16px;
      margin: 0 0 0 2px;
    }
  }
  .delete-icon-main {
    user-select: none;
    font-size: 16px;
    cursor: pointer;
    color: white;
    vertical-align: text-bottom;
  }
}
