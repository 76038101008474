
.bot-field-item {
  margin: 5px;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 1350px;
}

.bot-field-item-header {
  border: 1px solid #777;
  .bot-field-item-num, .bot-field-item-field-id-container, .bot-field-item-type,
  .bot-field-item-text, .bot-field-item-height, .bot-field-item-predecessors, .bot-field-item-successors,
  .bot-field-item-actions {
    font-weight: bold;
  }
}

.bot-field-item-num, .bot-field-item-field-id-container, .bot-field-item-type,
.bot-field-item-text, .bot-field-item-height, .bot-field-item-predecessors, .bot-field-item-successors,
.bot-field-item-actions {
  padding: 7px;
  align-items: center;
  display: flex;
  border-right: 1px solid #f5f5f5;
  font-size: 11px;
}

.bot-field-item-num {
  width: 50px;
  font-weight: bold;
}

.bot-field-item-field-id-container {
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  ul {
    margin-bottom: 0;
  }
}

.bot-field-item-field-id {
  cursor: pointer;
  font-size: 11px;
  &:hover {
    color: blue;
    text-decoration: underline;
  }
}

.bot-field-item-field-id-children {
  cursor: pointer;
  font-size: 11px;
  &:hover {
    color: blue;
    text-decoration: underline;
  }
}

.bot-field-item-type {
  width: 100px;
}

.bot-field-item-text {
  width: 300px;
  textarea {
    width: 270px;
  }
}

.bot-field-item-height {
  width: 100px;
}

.bot-field-item-actions {
  width: 150px;
  align-items: center;
  justify-content: center;
}

.bot-field-item-predecessors, .bot-field-item-successors {
  width: 200px;
  flex-wrap: wrap;
}

.bot-field-item-predecessor, .bot-field-item-successor {
  font-size: 11px;
  cursor: pointer;
  margin-right: 5px;

  &:hover {
    color: blue;
    text-decoration: underline;
  }
}
