.suggestion-input-container {
  display: inline-block;
  position: relative;
  margin: 3px 3px 3px 0;

  .suggestion-input {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 5px;
    outline: 0;
    box-shadow: none;
  }
  .suggestion-list-container {
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 160px;
    overflow-y: auto;
    background-color: white;
    border: 1px solid #f5f5f5;
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);

    .suggestion-list {
      padding: 5px 10px;
      border-bottom: 1px solid #f5f5f5;
      cursor: pointer;
      font-size: 14px;
      background-color: white;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}

